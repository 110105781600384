import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import Inicio from "./pages/Inicio.jsx"
import Painel from "./pages/Painel.jsx"

export default function Rotas() {
    return <BrowserRouter>
        <Routes>
            <Route path="/" element={<Inicio/>}/>
            <Route path="/painel" element={<Painel/>}/>
            <Route path="*" element={<h1>Caminho Não Existe</h1>}/>
        </Routes>
    </BrowserRouter>
}