import axios from "axios"

const api = axios.create({
    baseURL: "https://painel-impresscione-back-end.vercel.app/",
})

export async function loginUser(user, senha){

    try{
        const resposta = await api.post("/login", {user, senha})
        if(resposta.status === 200){
            return{
                status: true,
                mensagem: resposta.data
            }
        }else{
            return {
                status: false,
                mensagem: resposta.data
            }
        }

    }catch(erro){
        if(erro.request.status === 401){
            return {
                status: false,
                mensagem: erro.response.data
            }
    
        }
    }
}
