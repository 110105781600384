import React, { useState } from "react"
import { useNavigate } from "react-router-dom"


import estilo from "../styles/LoginStyle.module.css"
import Loading from "./Loading.jsx"
import { loginUser } from "../services/api.js"

export default function Login(props) {
  const navigate = useNavigate()
  const [load, setLoad] = useState(false)
  const [user, setUser] = useState("")
  const [senha, setSenha] = useState("")

  async function Login() {
    setLoad(true)
    
    if(!user || !senha){
      return setLoad(false)
    }
    const data = await loginUser(user, senha)    
    if (data.status) {
      sessionStorage.setItem("Impresscione", true)
      navigate("/painel")
    } else {
      sessionStorage.setItem("Impresscione", false)
    }
    setLoad(false)
  }
  if (load) {
    return <Loading />

  } else {
    return (
      <div className={estilo.index}>
        <img className={estilo.logo} alt="LOGO" src="/assets/logo.png" width="300" />
        <form className={estilo.form}>
          <input onChange={(event) => { setUser(event.target.value) }} type="text" placeholder="Usuário" required/>
          <input onChange={(event) => { setSenha(event.target.value) }} type="password" placeholder="Senha" required />
          <button onClick={Login} >ENTRAR</button>
        </form>

        <div className={estilo.avisos}>
          <h2>🚧 ATENÇÃO 🚧</h2>
          <p>O painel ainda está em desenvolvimento, portanto algumas funcionalidades, design, etc., podem não estar totalmente finalizadas.</p>
        </div>
      </div>
    )
  }
}