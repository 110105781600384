import React from "react"
import { Container, Nav, Navbar, Offcanvas } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGear, faHouse, faRightToBracket } from "@fortawesome/free-solid-svg-icons"

import estilo from "../styles/TopBarStyle.module.css"

export default function TopBar() {

    function sair(){
        sessionStorage.removeItem("Impresscione")
    }


    return (
        <div className={estilo.index} href="/">
            <div className={estilo.logoNome} >
                <img src="/assets/favIcon.png" alt="LOGO" height={50} />
                <h1>Painel Impresscione</h1>
            </div>

            <div className={estilo.menu}>
                <Navbar expand={false} variant="dark" >
                    <Container fluid>
                        <Navbar.Toggle />

                        <Navbar.Offcanvas placement="end" style={{ backgroundColor: "#212121", border: "none" }} data-bs-theme="dark">
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title >
                                    Painel Impresscione
                                </Offcanvas.Title>
                            </Offcanvas.Header>

                            <Offcanvas.Body>
                                <Nav className="justify-content-end flex-grow-1 pe-3" >
                                    <Nav.Link href="/painel"><FontAwesomeIcon icon={faHouse} color="#fff" /> Início</Nav.Link>
                                    <Nav.Link href="#" disabled style={{ opacity: 0.5 }}><FontAwesomeIcon icon={faGear} color="#fff" /> Configuração</Nav.Link>
                                    <Nav.Link href="/" onClick={sair}style={{ color: "#a63443" }}><FontAwesomeIcon icon={faRightToBracket} color="#a63443" /> SAIR</Nav.Link>
                                </Nav>
                            </Offcanvas.Body>
                        </Navbar.Offcanvas>
                    </Container>
                </Navbar>
            </div>
        </div>
    )
}