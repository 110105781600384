import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import estilo from "../styles/PainelStyle.module.css"
import TopBar from "../components/TopBar.jsx"

import SemTools from "../tools/tool/SemTools.jsx"
import Dominios from "../tools/tool/Dominios.jsx"

export default function Painel() {
    const navigate = useNavigate()
    const [tool, setTool] = useState(0)

    useEffect(() => {
        if (!sessionStorage.getItem("Impresscione") || sessionStorage.getItem("Impresscione") === "false") return navigate("/")
    }, [navigate])

    function Tools() {
        if (tool === 1) {
            return <Dominios/>
        } else if (tool === 2) {
            return alert("B")
        } else {
            return <SemTools />
        }
    }

    return <div className={estilo.index}>
        <TopBar />

        <div className={estilo.menu}>
            <button onClick={() => { setTool(1) }}>Domínios</button>
            <button onClick={() => { setTool(2) }}>Rádoios Web</button>
        </div>
        <div className={estilo.tools}>
            {Tools()}
        </div>
    </div>

}