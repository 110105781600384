import React, { useEffect } from "react";
import Login from "../components/Login";
import { useNavigate } from "react-router-dom";


export default function Inicio() {
    const navigate = useNavigate()
    useEffect(() => {
        if(sessionStorage.getItem("Impresscione") || sessionStorage.getItem("Impresscione") === "true") return navigate("/painel")
    }, [navigate])

    return <Login/>
}